@import "./mixins/index";
@import "./selectNone.less";

@import './modal-video.css';

.m-head{
    margin-bottom: 15px;
    color: #666;
}
.m-img-thumbs{
    width: 120px;
    height: 40px;
    border: 2px solid #f9f9f9;
    img{
        width: 120px;
        height: 40px;
    }
}
@root-entry-name: default;@primary-color: #00376d;