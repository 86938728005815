
.primary-warp {
	padding: @primary-layout-padding;
}

.clearfix {
  display: block;
  zoom: 1;
}
.clearfix:after {
  content: " ";
  display: block;
  font-size: 0;
  height: 0;
  clear: both;
  visibility: hidden;
}

.divider {
    margin: 0 6px;
    display: inline-block;
    height: 8px;
    width: 1px;
    background: #ccc;
}

.blue-i {
    color: #108ee9;
}

.blue {
    color: #5B9BD5;
}

.grey {
    color: grey;
}
.red {
    color: #FF0000;
}

.green {
    color: #00B050;
}

.yellow {
    color: #FF9900;
}
.purple {
    color: purple;
}

.cursor{
    cursor: pointer;
}

.tc {
    text-align: center;
}
.tl {
    text-align: left;
}

.tr {
    text-align: right;
}

.flex {
    display: flex;
}
.flex-column {
    flex-direction: column;
}

.flex-1 {
    flex: 1;
}

.fl {
    float: left;
}

.fr {
    float: right;
}

.cp {
    cursor: pointer;
}

.pr {
    position: relative;
}

.b-inline {
    display: inline-block;
}
.d-n {
    display: none;
}
.v-l {
    vertical-align: middle;
}
.v-b {
    vertical-align: baseline;
}
.v-c {
    display:flex;
	justify-content:center;
	align-items:center;
}
.v-t {
    vertical-align: top;
}
.text-wrap {
    white-space: normal;
    word-wrap: break-word;
    word-break: break-all;
}
.block-ct {
    margin: 0 auto;
    display: block
}

.text-overflow {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.project-fre-error{
    .ant-legacy-form-explain{
        padding-left: 16.6%
    }
}


.aim-error{
    .ant-legacy-form-explain{
        padding-left: 16.6%;
        margin-top: -20px
    }
}
.aim-order-error{
    .ant-legacy-form-explain{
        padding-left: 12.6%;
        margin-top: -20px
    }
}

.window-scrollbar {
	::-webkit-scrollbar {
		width: 6px;
		height: 6px;
	}

	::-webkit-scrollbar-track {
		width: 6px;
		background: rgba(#101F1C, 0.1);
		-webkit-border-radius: 2em;
		-moz-border-radius: 2em;
		border-radius: 2em;
	}

	::-webkit-scrollbar-thumb {
		background-color: rgba(#101F1C, 0.5);
		background-clip: padding-box;
		min-height: 28px;
		-webkit-border-radius: 2em;
		-moz-border-radius: 2em;
		border-radius: 2em;
	}

	::-webkit-scrollbar-thumb:hover {
		background-color: rgba(#101F1C, 1);
	}

}

.maginLeft-200{
    margin-left: 217px;
}



.table-tips-id {
    font-size: 12px;
    color: #666;
}

.padding-no .ant-card-body{
    padding: 0;
}

.flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.operation {
    color: #00376d;
    cursor: pointer;
}