/*重置样式表 */

body {
    background: #f0f2f5;
}

body, div, dl, dt, dd, ul, ol, li, h1, h2, h3, h4, h5, h6, form, fieldset, input, button, textarea, p, th, td, b, i, strong {
  padding: 0;
  margin: 0;
}

img {
  border: 0
}

a {
  text-decoration: none;
  outline: none
}

li {
  list-style: none
}

input, button, textarea, select {
  font-size: 100%;
}

i, em {
    font-style: normal;
}
